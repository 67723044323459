
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'EmptySearchResult',
  props: {
    searchKeyword: {
      type: String,
      default: '',
    },
  },
  components: {
    CsFlex,
  },
})
