var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cs-simple-table", { staticClass: "explorer-list" }, [
    _c("thead", [
      _vm.projects.length > 0
        ? _c("tr", [
            _c("th"),
            _c("th"),
            _c("th", [_vm._v(_vm._s(_vm.$t("Név")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("Státusz")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("Léltrehozás dátuma")))]),
          ])
        : _c("tr", [
            _c("th"),
            _c("th"),
            _c("th", [_vm._v(_vm._s(_vm.$t("Név")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("Státusz")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("Létrehozás dátuma")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("Méret")))]),
          ]),
    ]),
    _c(
      "tbody",
      [
        _vm._l(_vm.projects, function (project) {
          return _c(
            "tr",
            {
              key: project.id,
              class: {
                selected: _vm.selectedItems.includes(project.id.toString()),
              },
              on: {
                dblclick: function ($event) {
                  _vm.navigate(project.id.toString())
                },
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.select($event, project.id.toString())
                },
              },
            },
            [
              _c(
                "td",
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 pt-0",
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      "input-value": _vm.selectedItems.includes(
                        project.id.toString()
                      ),
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-folder"),
                  ]),
                ],
                1
              ),
              _c("td", [
                _c("strong", [_vm._v(_vm._s(project.name))]),
                _c("div", { staticClass: "text-caption text--secondary" }, [
                  _vm._v(" " + _vm._s(_vm.$t("Projekt")) + " "),
                ]),
              ]),
              _c("td", [
                _vm._v(_vm._s(_vm.$t(_vm.getProjectStatus(project.models)))),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      Intl.DateTimeFormat().format(
                        new Date(project.creationDate)
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          )
        }),
        _vm._l(_vm.models, function (model) {
          return _c(
            "tr",
            {
              key: model.id,
              class: {
                selected: _vm.selectedItems.includes(model.id.toString()),
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.select($event, model.id.toString())
                },
                dblclick: function ($event) {
                  _vm.handleFileDoubleClick(model.id.toString())
                },
              },
            },
            [
              _c(
                "td",
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 pt-0",
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      readonly: "",
                      "input-value": _vm.selectedItems.includes(
                        model.id.toString()
                      ),
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c(
                    "v-img",
                    {
                      class: `list-preview-image ${
                        _vm.fadedModelKeys.includes(model.id) ? "faded" : ""
                      }`,
                      attrs: { src: model.previewUrl, width: "35" },
                    },
                    [
                      [
                        model.previewUrl
                          ? _vm._t("imageDefaultSlot")
                          : _c(
                              "cs-flex",
                              {
                                attrs: { "fill-width": "", "fill-height": "" },
                              },
                              [_c("default-preview-svg")],
                              1
                            ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("td", [_c("strong", [_vm._v(_vm._s(model.name))])]),
              _c("td", [
                _c(
                  "div",
                  {
                    staticClass: "status-stripe",
                    style: { backgroundColor: _vm.getModelStatusColor(model) },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t(_vm.getModelStatus(model))) + " "
                    ),
                  ]
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      Intl.DateTimeFormat().format(
                        new Date(model.lastModificationDate)
                      )
                    ) +
                    " " +
                    _vm._s(
                      new Date(model.lastModificationDate).toLocaleTimeString(
                        undefined,
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(model.getFormattedFileSize()))]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }