var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-dialog",
    {
      attrs: {
        value: _vm.value,
        header: false,
        title: _vm.$t("Model import"),
        persistent: "",
        width: "90%",
        maxWidth: "1200px",
        "fill-width": "",
        "fill-height": "",
      },
      on: { input: _vm.close },
    },
    [
      _c("div", { staticClass: "d-flex align-start h-100 overflow-hidden" }, [
        _c(
          "div",
          { staticClass: "py-8 px-6 d-flex flex-column file-system" },
          [
            _c(
              "div",
              { staticClass: "px-0 align-center justify-center" },
              [_c("steel-space-logo-svg", { staticClass: "mb-4" })],
              1
            ),
            _vm.fileSystemLoading
              ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "my-models-btn" },
              [
                _c(
                  "cs-btn",
                  {
                    staticClass: "justify-start my-2",
                    attrs: {
                      color: "gray",
                      block: "",
                      large: "",
                      outlined: _vm.currentFolderType === "own",
                      disabled: _vm.isLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setCurrentFolderType("own")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("My models")))]
                ),
              ],
              1
            ),
            _vm.folderTree && _vm.currentFolderType == "own"
              ? _c("cs-filetree", {
                  staticClass: "overflow-auto",
                  attrs: {
                    items: _vm.folderTree.children,
                    outlined: _vm.currentFolderType === "own",
                    value: _vm.currentFolderId,
                    loading: _vm.isLoading,
                  },
                  on: { input: _vm.navigate },
                })
              : _vm._e(),
            _c(
              "cs-btn",
              {
                staticClass: "justify-start my-2",
                attrs: {
                  color: "gray",
                  block: "",
                  large: "",
                  outlined: _vm.currentFolderType === "shared",
                  disabled: _vm.isLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.setCurrentFolderType("shared")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Shared with me")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex flex-column flex-grow-1 pa-8 pt-10 body" },
          [
            _c(
              "cs-flex",
              {
                class: _vm.isLoading ? "fs-loading" : "",
                staticStyle: { "min-height": "63px" },
                attrs: { justify: "space-between" },
              },
              [
                _vm.isLoading
                  ? _c("v-progress-linear", {
                      attrs: { indeterminate: "", absolute: "", top: "" },
                    })
                  : _vm._e(),
                _c(
                  "cs-flex",
                  { attrs: { gap: "0.5rem", inline: "" } },
                  [
                    _c("cs-text-field", {
                      staticStyle: { "max-width": "fit-content" },
                      attrs: {
                        dense: "",
                        outlined: "",
                        "hide-details": "",
                        placeholder: _vm.$t("Keresés aktuális mappában"),
                        "append-icon": "mdi-magnify",
                        clearable: "",
                      },
                      model: {
                        value: _vm.searchKeyword,
                        callback: function ($$v) {
                          _vm.searchKeyword = $$v
                        },
                        expression: "searchKeyword",
                      },
                    }),
                    _c("div", { staticClass: "flex-grow-1" }),
                    _c("cs-select", {
                      staticClass: "ordering-select",
                      attrs: {
                        dense: "",
                        solo: "",
                        flat: "",
                        "hide-details": "",
                        "background-color": "transparent",
                        "append-icon": "mdi-chevron-down",
                        "menu-props": { bottom: true, offsetY: true },
                        items: _vm.orderByOptions,
                      },
                      model: {
                        value: _vm.orderBy,
                        callback: function ($$v) {
                          _vm.orderBy = $$v
                        },
                        expression: "orderBy",
                      },
                    }),
                    _c("v-divider", {
                      staticClass: "my-2",
                      attrs: { vertical: "" },
                    }),
                    _c(
                      "v-btn-toggle",
                      {
                        attrs: {
                          borderless: "",
                          "background-color": "transparent",
                          mandatory: "",
                        },
                        model: {
                          value: _vm.viewMode,
                          callback: function ($$v) {
                            _vm.viewMode = $$v
                          },
                          expression: "viewMode",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              fab: "",
                              text: "",
                              small: "",
                              value: "list",
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-format-list-checkbox")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              fab: "",
                              text: "",
                              small: "",
                              value: "grid",
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-view-grid-outline")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", {
                      staticClass: "my-2",
                      attrs: { vertical: "" },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: !(
                            _vm.singleSelectedFile || _vm.singleSelectedFolder
                          ),
                          fab: "",
                          text: "",
                          small: "",
                          color: _vm.infoBarToggle ? "primary" : undefined,
                        },
                        on: { click: _vm.toggleInfoBar },
                      },
                      [_c("v-icon", [_vm._v("mdi-information-outline")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "py-8 px-8 flex-grow-1 model-container",
                attrs: { vertical: "", "fill-height": "", gap: "1.5rem" },
              },
              [
                _vm.searchKeyword
                  ? _c(
                      "h4",
                      {
                        class: _vm.isLoading ? "fs-loading" : "",
                        staticStyle: { "font-weight": "400" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Search results for: "${_vm.searchKeyword}"`
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "cs-flex",
                  {
                    class: _vm.isLoading ? "fs-loading" : "",
                    staticStyle: { "overflow-y": "hidden" },
                    attrs: {
                      "fill-width": "",
                      "fill-height": "",
                      align: "stretch",
                      gap: "2rem",
                    },
                  },
                  [
                    _vm.currentFolderOrSearchResult &&
                    ((_vm.currentFolderOrSearchResult.childFolders &&
                      _vm.currentFolderOrSearchResult.childFolders.length) ||
                      (_vm.currentFolderOrSearchResult.childModels &&
                        _vm.currentFolderOrSearchResult.childModels.length))
                      ? _c(
                          "cs-flex",
                          {
                            staticClass: "explorer-container",
                            attrs: {
                              "fill-height": "",
                              "fill-width": "",
                              align: "flex-start",
                            },
                            on: { click: _vm.unselectAll },
                            nativeOn: {
                              contextmenu: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.preprocessContextMenu(
                                  $event,
                                  _vm.item.id
                                )
                              },
                            },
                          },
                          [
                            _vm.viewMode === "grid"
                              ? _c(
                                  "cs-grid",
                                  {
                                    attrs: {
                                      "fill-width": "",
                                      gap: "1.5rem",
                                      minColWidth: "200",
                                      "auto-fill-cols": "",
                                      equalCols: "",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.currentFolderOrSearchResult
                                        .childFolders,
                                      function (folder) {
                                        return _c("cs-explorer-folder-card", {
                                          key: folder.id,
                                          attrs: {
                                            folderName: folder.name,
                                            selected:
                                              _vm.selectedFolders.includes(
                                                folder.id
                                              ),
                                          },
                                          on: {
                                            dblclick: function ($event) {
                                              return _vm.navigate(folder.id)
                                            },
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.select(
                                                $event,
                                                folder.id
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    _vm._l(
                                      _vm.currentFolderOrSearchResult
                                        .childModels,
                                      function (model) {
                                        return _c("cs-explorer-file-card", {
                                          key: model.id,
                                          attrs: {
                                            faded:
                                              _vm.disabledModelIds.includes(
                                                model.id
                                              ) ||
                                              !_vm.isModelOpenable(
                                                model.status
                                              ),
                                            fileName: model.name,
                                            lastOpen:
                                              model.lastModificationDate,
                                            image: model.previewUrl,
                                            selected:
                                              _vm.selectedModels.includes(
                                                model.id
                                              ),
                                            shared:
                                              model.getShares().length > 0,
                                            status: _vm.getModelStatus(model),
                                            statusColor: "#969696",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.select(
                                                $event,
                                                model.id
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.viewMode === "list"
                              ? _c(
                                  "cs-simple-table",
                                  { staticClass: "explorer-list" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th"),
                                        _c("th"),
                                        _c("th", [
                                          _vm._v(_vm._s(_vm.$t("Name"))),
                                        ]),
                                        _c("th", [
                                          _vm._v(_vm._s(_vm.$t("Shared"))),
                                        ]),
                                        _c("th", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Last modified"))
                                          ),
                                        ]),
                                        _c("th", [
                                          _vm._v(_vm._s(_vm.$t("Size"))),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.currentFolderOrSearchResult
                                            .childFolders,
                                          function (folder) {
                                            return _c(
                                              "tr",
                                              {
                                                key: folder.id,
                                                class: {
                                                  selected:
                                                    _vm.selectedFolders.includes(
                                                      folder.id
                                                    ),
                                                },
                                                on: {
                                                  dblclick: function ($event) {
                                                    return _vm.navigate(
                                                      folder.id
                                                    )
                                                  },
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.select(
                                                      $event,
                                                      folder.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        readonly: "",
                                                        "input-value":
                                                          _vm.selectedFolders.includes(
                                                            folder.id
                                                          ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { large: "" } },
                                                      [_vm._v("mdi-folder")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v(_vm._s(folder.name)),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-caption text--secondary",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("Folder")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c("td", [_vm._v("-")]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        Intl.DateTimeFormat().format(
                                                          new Date(
                                                            folder.creationDate
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("td", [_vm._v("-")]),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._l(
                                          _vm.currentFolderOrSearchResult
                                            .childModels,
                                          function (model) {
                                            return _c(
                                              "v-tooltip",
                                              {
                                                key: model.id,
                                                attrs: { right: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "tr",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  class: {
                                                                    selected:
                                                                      _vm.selectedModels.includes(
                                                                        model.id
                                                                      ),
                                                                    faded:
                                                                      _vm.disabledModelIds.includes(
                                                                        model.id
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.select(
                                                                          $event,
                                                                          model.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "tr",
                                                                attrs,
                                                                false
                                                              ),
                                                              _vm.disabledModelIds.includes(
                                                                model.id
                                                              )
                                                                ? on
                                                                : undefined
                                                            ),
                                                            [
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "v-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        readonly:
                                                                          "",
                                                                        "input-value":
                                                                          _vm.selectedModels.includes(
                                                                            model.id
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c("v-img", {
                                                                    attrs: {
                                                                      src: model.previewUrl,
                                                                      width:
                                                                        "35",
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                              _c("td", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      model.name
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  model.getShares()
                                                                    .size
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "20",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-account-multiple-outline"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "-"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      Intl.DateTimeFormat().format(
                                                                        new Date(
                                                                          model.lastModificationDate
                                                                        )
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    model.getFormattedFileSize()
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Already imported")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "cs-flex",
                          { attrs: { "fill-width": "", "fill-height": "" } },
                          [
                            _vm.searchKeyword
                              ? _c(
                                  "cs-flex",
                                  { attrs: { vertical: "", gap: "0.25rem" } },
                                  [
                                    _c(
                                      "cs-flex",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "empty-search-magnify mb-4",
                                          },
                                          [_vm._v("mdi-magnify")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("cs-flex", [
                                      _c("h4", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("Sorry, no results found")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("cs-flex", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "Check the spelling or try a different keyword"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(_vm._s(_vm.$t("Empty folder"))),
                                ]),
                          ],
                          1
                        ),
                    _c(
                      "cs-simple-dialog",
                      {
                        attrs: {
                          persistent: "",
                          origin: "top right",
                          width: 350,
                        },
                        model: {
                          value: _vm.infoBarToggle,
                          callback: function ($$v) {
                            _vm.infoBarToggle = $$v
                          },
                          expression: "infoBarToggle",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "info-bar-dialog" },
                          [
                            _vm.singleSelectedFile && !_vm.singleSelectedFolder
                              ? _c("cs-model-info", {
                                  attrs: {
                                    title: _vm.singleSelectedFile.name,
                                    thumbnail:
                                      _vm.singleSelectedFile.previewUrl,
                                    description:
                                      _vm.singleSelectedFile.description,
                                    infos: _vm.getFileInfo(
                                      _vm.singleSelectedFile
                                    ),
                                    owner:
                                      _vm.singleSelectedFile.originUserData,
                                    shares: _vm.singleSelectedFile
                                      ? Array.from(
                                          _vm.singleSelectedFile
                                            .getShares()
                                            .values()
                                        )
                                      : [],
                                  },
                                  on: {
                                    close: function ($event) {
                                      _vm.infoBarToggle = false
                                    },
                                  },
                                })
                              : _vm.singleSelectedFolder
                              ? _c("cs-model-info", {
                                  attrs: {
                                    title: _vm.singleSelectedFolder.name,
                                    folder: "",
                                    infos: _vm.getFolderInfo(
                                      _vm.singleSelectedFolder
                                    ),
                                  },
                                  on: {
                                    close: function ($event) {
                                      _vm.infoBarToggle = false
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("cs-context-menu", {
                  attrs: { items: [], x: _vm.x, y: _vm.y },
                  on: { select: _vm.contextItemSelected },
                  model: {
                    value: _vm.contextMenu,
                    callback: function ($$v) {
                      _vm.contextMenu = $$v
                    },
                    expression: "contextMenu",
                  },
                }),
              ],
              1
            ),
            _c("div", [
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "me-3",
                      attrs: { outlined: "", disabled: _vm.isLoading },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Mégse")))]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        disabled:
                          _vm.isLoading || !_vm.isAllSelectedModelsOpenable,
                      },
                      on: { click: _vm.handleImportClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Importálás")))]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }