
import Vue from 'vue'
import { OnStockProject, OnStockUser } from '@/modules/onstock'
import ManageCollaboratorsDialog from '../Dialogs/ManageCollaboratorsDialog.vue'

export default Vue.extend({
  name: 'ExplorerManageCollaborators',
  components: {
    ManageCollaboratorsDialog,
  },
  data() {
    return {
      manageCollaboratorsDialog: false,
    }
  },
  props: {
    icon: {
      type: String,
      default: 'mdi-share-variant',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number || String,
    },
    currentProject: {
      type: Object as () => OnStockProject,
    },
    collaborators: {
      type: Array as () => OnStockUser[],
      default: () => [],
    },
  },
})
