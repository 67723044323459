var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { attrs: { "fill-width": "", "fill-height": "" } },
    [
      _vm.searchKeyword
        ? _c(
            "cs-flex",
            {
              staticClass: "empty-search",
              attrs: { vertical: "", gap: "0.25rem" },
            },
            [
              _c(
                "cs-flex",
                [
                  _c("v-icon", { staticClass: "mb-4" }, [
                    _vm._v("mdi-magnify"),
                  ]),
                ],
                1
              ),
              _c("h4", [
                _vm._v(" " + _vm._s(_vm.$t("Sorry, no results found")) + " "),
              ]),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Check the spelling or try a different keyword")
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _c("div", [_vm._v(_vm._s(_vm.$t("Empty folder")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }