
import { CsGrid, CsExplorerFileCard } from '@consteel/csuetify'
import Vue from 'vue'
import { SteelspaceModel } from '@/modules/model'
import { OnStockProject, OnStockModel, getStatusItems } from '@/modules/onstock'
import ExplorerProjectCard from './ExplorerProjectCard.vue'
import ConsteelBadgeSvg from '@/assets/ConsteelBadge.svg.vue'
import IfcConverterBadgeSvg from '@/assets/IfcConverterBadge.svg.vue'
import DefaultPreviewSvg from '../../assets/DefaultPreview.svg.vue'

import { OriginType } from '@/modules/model/types/SteelspaceModelResponse'

export default Vue.extend({
  name: 'ExplorerGridView',
  components: {
    CsGrid,
    ExplorerProjectCard,
    CsExplorerFileCard,
    ConsteelBadgeSvg,
    IfcConverterBadgeSvg,
    DefaultPreviewSvg,
  },
  props: {
    selectedItems: {
      type: Array as () => Array<string>,
      default: [],
    },
    fadedModelKeys: {
      type: Array as () => Array<string>,
      default: [],
    },
    models: {
      type: Array as () => SteelspaceModel[],
      default: undefined,
    },
    projects: {
      type: Array as () => OnStockProject[],
      default: undefined,
    },
    onStockModels: {
      type: Array as () => OnStockModel[],
      default: undefined,
    },
  },
  methods: {
    navigate(folderId: string, skipHistory = false): void {
      this.$emit('navigate', { folderId, skipHistory })
    },
    select(event: MouseEvent, id: string): void {
      this.$emit('select', { event, id })
    },
    handleFileDoubleClick(modelId: string): void {
      this.$emit('handleFileDoubleClick', modelId)
    },
    getOnStockModelById(modelId: string): OnStockModel | undefined {
      return this.onStockModels.find((model) => model.steelspaceId === modelId)
    },
    getModelStatus(model: SteelspaceModel): string {
      const onStockModel = this.getOnStockModelById(model.isOrigin ? model.id : model.originModelId)
      return onStockModel ? getStatusItems()[onStockModel.status].text : ''
    },
    getModelStatusColor(model: SteelspaceModel): string {
      const onStockModel = this.getOnStockModelById(model.isOrigin ? model.id : model.originModelId)
      return onStockModel ? getStatusItems()[onStockModel.status].color : ''
    },
    modelUploadBadge(uploadOrigin: OriginType) {
      switch (uploadOrigin) {
        case 'consteel':
          return ConsteelBadgeSvg
        case 'ifcConverter':
          return IfcConverterBadgeSvg
      }
    },
  },
})
