var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        fill: _vm.color,
        viewBox: "0 0 580 580",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "m538.31,336.63l-.13-36.39.05-.13-70.76-31.86v-16.66l-.18.11.05-.13-48.1-23.43,45.98-27.28.42-54.41.04-.05-.04-.02v-.04l-.04.02-72.71-28.15-77.3,40.53-53.19-25.01L36.73,246.88l-.13-.05v.13l-.05.02.05.11.33,49.5,216.15,126.52,5.6-3.01.09,5.76,65.05,35.83v.09l214.5-125.15Zm-82.49-81.06l-124.52,67.26-63.98-33.62,125.4-64.37,63.1,30.74Zm-62.52-125.8l60.04,23.25-200.34,107.57-54.17-29.06,194.47-101.76Zm-137.6,231.22L54.36,251.05l210.92-105.97,41.84,19.67-117.66,61.69v10.13s-17.56,9.44-17.56,9.44l147.54,80.72-63.73,34.25Zm10,21.56l200.13-106,59.13,26.63-199.84,109.82-59.41-30.45Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }