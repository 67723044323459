
import Vue from 'vue'
import { ExplorerViewMode } from '../../store/explorer.store'

export default Vue.extend({
  name: 'ViewModeSelect',
  props: {
    value: {
      type: String as () => ExplorerViewMode,
    },
  },
})
