
import Vue, { VueConstructor } from 'vue'
import { IThemeable, CsExplorerCard, CsFlex } from '@consteel/csuetify'
import Themeable from 'vuetify/lib/mixins/themeable'

export default (Vue as VueConstructor<IThemeable>).extend({
  name: 'CsExplorerFolderCard',
  mixins: [Themeable],
  components: {
    CsFlex,
    CsExplorerCard,
  },
  props: {
    folderName: {
      required: true,
    },
    selected: {
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'cs-explorer-folder-card': true,
        selected: this.selected,
        ...this.themeClasses,
      }
    },
  },
})
