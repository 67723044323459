
import SteelSpaceLogoSvg from '@/assets/SteelspaceLogo.svg.vue'
import {
  FolderType,
  getUserFoldersSubscribe,
  HydratedSteelspaceFolder,
  SteelspaceFolder,
} from '@/modules/folder'
import { getUserModelsSubscribe, ModelStatusType, SteelspaceModel } from '@/modules/model'
import { explorerStore, ExplorerViewMode } from '@/store/explorer.store'
import {
  CsBtn,
  CsContextMenu,
  CsDialog,
  CsExplorerFileCard,
  CsExplorerFolderCard,
  CsFiletree,
  CsFlex,
  CsGrid,
  CsModelInfo,
  CsSelect,
  CsSimpleDialog,
  CsSimpleTable,
  CsTextField,
} from '@consteel/csuetify'
import { contextable } from '@consteel/csuetify/src/mixins/contextable'
import Vue from 'vue'
import {
  convertHydratedToDirectoryTree,
  FileTreeNode,
  formatSize,
  getHydratedFolder,
  hydrate,
  Ordering,
  searchInFolder,
} from '../../services'

type KeyValueObject = {
  key: unknown
  value: unknown
}

export default Vue.extend({
  name: 'CloudOpen',
  components: {
    CsFlex,
    CsGrid,
    CsTextField,
    CsSelect,
    CsDialog,
    CsModelInfo,
    CsSimpleDialog,
    CsFiletree,
    CsSimpleTable,
    CsBtn,
    CsExplorerFolderCard,
    CsExplorerFileCard,
    CsContextMenu,
    SteelSpaceLogoSvg,
  },
  mixins: [contextable],
  data: () => ({
    publicModels: null as SteelspaceModel[] | null,
    currentFolderId: '',
    currentFolderType: 'own' as FolderType,
    selectedModels: [] as string[],
    selectedFolders: [] as string[],
    orderByOptions: [
      { value: 'alphabetical', text: 'A-Z' },
      { value: 'recent', text: 'Recent' },
    ] as { value: Ordering; text: string }[],
    orderBy: 'alphabetical' as Ordering,
    viewMode: 'grid' as ExplorerViewMode,
    infoBarToggle: false,
    showDeleteConfirmationModal: false,
    showNewFolderModal: false,
    newFolderModalLoading: false,
    newFolderErrorMessage: '',
    showMoveToModal: false,
    moveToModalLoading: false,
    searchKeyword: '',
    currentFile: new SteelspaceModel(),
    saveLoading: false,
    fileSystemLoading: false,
  }),
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAllSelectedModelsOpenable(): boolean {
      const isAllModelOpenable = this.selectedModels.every((modelId) => {
        const model = this.models.find((model) => model.id === modelId)

        if (model?.status === 'invalid') return false
        return true
      })

      return isAllModelOpenable
    },
    disabledModelIds(): string[] {
      return explorerStore.importedSteelspaceModelIds
    },
    isLoading(): boolean {
      return this.loading || this.fileSystemLoading
    },
    folders(): SteelspaceFolder[] {
      return getUserFoldersSubscribe()
    },
    models(): SteelspaceModel[] {
      return getUserModelsSubscribe()
    },
    rootFolder(): HydratedSteelspaceFolder | undefined {
      return hydrate(this.folders, this.models, this.currentFolderType, this.orderBy)
    },
    folderTree(): FileTreeNode | undefined {
      return convertHydratedToDirectoryTree(
        hydrate(this.folders, this.models, this.currentFolderType)
      )
    },
    currentFolder(): HydratedSteelspaceFolder | undefined {
      return getHydratedFolder(
        this.folders,
        this.models,
        this.publicModels,
        this.currentFolderId,
        this.orderBy
      )
    },
    currentFolderOrSearchResult(): HydratedSteelspaceFolder | undefined {
      if (this.currentFolder) {
        const currentFolder = this.currentFolder

        return this.searchKeyword && currentFolder
          ? searchInFolder(currentFolder, this.searchKeyword)
          : currentFolder
      }

      return undefined
    },
    singleSelectedFile(): SteelspaceModel | undefined {
      if (this.selectedModels.length !== 1) {
        return undefined
      }

      return (
        this.models.find((model) => model.id === this.selectedModels[0]) ||
        this.publicModels?.find((model) => model.id === this.selectedModels[0])
      )
    },
    singleSelectedFolder(): SteelspaceFolder | undefined {
      if (this.selectedFolders.length !== 1) {
        return undefined
      }

      return this.folders.find((folder) => folder.id === this.selectedFolders[0])
    },
    isOpenAllowed(): boolean {
      if (this.singleSelectedFile && !this.singleSelectedFile.isOrigin) {
        return this.singleSelectedFile.canDownloadModel.length >= 1
      }

      return true
    },
    showInfoBar(): boolean {
      return this.infoBarToggle && !!this.singleSelectedFile
    },
  },
  methods: {
    getModelStatus(model: SteelspaceModel): string {
      return !this.isModelOpenable(model.status)
        ? this.$tc('Unable to open model')
        : this.disabledModelIds.includes(model.id)
        ? this.$tc('Already imported')
        : ''
    },
    isModelOpenable(modelStatus: ModelStatusType): boolean {
      return modelStatus !== 'invalid'
    },
    handleImportClick(): void {
      this.$emit('submit', this.selectedModels)
    },
    navigate(folderId: string): void {
      this.currentFolderId = folderId
      this.unselectAll()
      this.searchKeyword = ''
    },
    // eslint-disable-next-line
    select(event: any, id: string): void {
      if (!event.ctrlKey) {
        this.selectedModels = []
        this.selectedFolders = []
      }
      const selectedFolder = this.folders.find((folder) => folder.id === id)
      const selectedModel = this.models.find((model) => model.id === id)

      if (selectedModel) {
        if (this.disabledModelIds.includes(selectedModel.id)) {
          return
        }

        Object.assign(this.currentFile, { ...selectedModel })
        if (this.selectedModels.includes(id)) {
          this.selectedModels = this.selectedModels.filter((item) => item != id)
        } else {
          this.selectedModels.push(id)
        }
      } else if (selectedFolder) {
        const childrenModelIds = this.models
          ?.filter((model) => model.parentId == id)
          .map((model) => model.id)
        if (this.selectedFolders.includes(id)) {
          this.selectedFolders = this.selectedFolders.filter((item) => item !== id)
          this.selectedModels = this.selectedModels.filter(
            (item) => !childrenModelIds.includes(item)
          )
        } else {
          this.selectedModels = [...this.selectedModels, ...childrenModelIds]
          this.selectedFolders = [...this.selectedFolders, id]
        }
      }
    },
    // eslint-disable-next-line
    unselectAll(event?: any) {
      if (!event || !event.ctrlKey) {
        this.selectedModels = []
        this.selectedFolders = []
      }

      this.currentFile = new SteelspaceModel()
    },
    close(): void {
      this.$emit('input', false)
    },
    getFolderInfo(folder: SteelspaceFolder): KeyValueObject[] {
      return [
        {
          key: this.$t('Created'),
          value: Intl.DateTimeFormat().format(new Date(folder.creationDate)),
        },
        {
          key: this.$t('Parent folder'),
          value: folder.parentName,
        },
        {
          key: this.$t('Size'),
          value: formatSize(folder.size),
        },
      ]
    },
    getFileInfo(file: SteelspaceModel): KeyValueObject[] {
      return [
        { key: 'Type', value: file.extension.toUpperCase() },
        {
          key: 'Storage used',
          value: file.getFormattedFileSize(),
        },
        { key: 'Location', value: file.isPublic ? 'Public' : file.parentName },
        { key: 'Owner', value: file.originUserData.name },
        {
          key: 'Created',
          value: Intl.DateTimeFormat().format(new Date(file.creationDate)),
        },
        {
          key: 'Last modified',
          value: Intl.DateTimeFormat().format(new Date(file.lastModificationDate)),
        },
      ]
    },
    onMoveToResult(): void {
      this.moveToModalLoading = false
      this.showMoveToModal = false
    },
    setCurrentFolderType(newType: FolderType): void {
      this.currentFolderType = newType
      if (newType === 'public') {
        this.currentFolderId = 'public'
        if (this.publicModels === null) {
          this.fileSystemLoading = true
        }
      } else {
        this.currentFolderId = this.rootFolder?.id || ''
      }
    },
    toggleInfoBar(): void {
      this.infoBarToggle = true
    },
  },
  watch: {
    value() {
      this.selectedModels = []
      this.selectedFolders = []
    },
    rootFolder() {
      if (this.rootFolder) this.currentFolderId = this.rootFolder.id
    },
    searchKeyword() {
      this.selectedModels = []
      this.selectedFolders = []
    },
  },
  mounted() {
    if (this.rootFolder) this.currentFolderId = this.rootFolder.id
  },
})
