import Vue from 'vue'
import { Ordering } from '../services'

export type ExplorerViewMode = 'list' | 'grid'

const state = Vue.observable({
  orderBy: 'alphabetical' as Ordering,
  viewMode: 'grid' as ExplorerViewMode,
  importedSteelspaceModelIds: [] as string[],
  searchKeyword: '',
  deleteFile: { loading: false, showDialog: false },
})

export const setImportedSteelspaceModelIds = (ids: string[]): void => {
  state.importedSteelspaceModelIds = ids
}

export const setExplorerSearchKeyword = (keyword: string): void => {
  state.searchKeyword = keyword
}

export const setDeleteFileLoading = (value: boolean): void => {
  state.deleteFile.loading = value
}

export const setDeleteFileShowDialog = (value: boolean): void => {
  state.deleteFile.showDialog = value
}

export const setExplorerOrderBy = (orderBy: Ordering): void => {
  state.orderBy = orderBy
}

export const setExplorerViewMode = (viewMode: ExplorerViewMode): void => {
  state.viewMode = viewMode
}

export const explorerStore = state as Readonly<typeof state>
