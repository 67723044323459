var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-grid",
    {
      staticClass: "mb-15",
      attrs: {
        "fill-width": "",
        gap: "1.5rem",
        minColWidth: "200",
        "auto-fill-cols": "",
        equalCols: "",
      },
    },
    [
      _vm._l(_vm.projects, function (project) {
        return _c("explorer-project-card", {
          key: project.id,
          attrs: {
            folderName: project.name,
            selected: _vm.selectedItems.includes(project.id.toString()),
          },
          on: {
            dblclick: function ($event) {
              _vm.navigate(project.id.toString())
            },
            click: function ($event) {
              $event.stopPropagation()
              _vm.select($event, project.id.toString())
            },
          },
        })
      }),
      _vm._l(_vm.models, function (model) {
        return _c("cs-explorer-file-card", {
          key: model.id,
          attrs: {
            fileName: model.name,
            lastOpen: model.lastModificationDate,
            image: model.previewUrl,
            selected: _vm.selectedItems.includes(model.id),
            shared: model.getShares().length > 0,
            faded: _vm.fadedModelKeys.includes(model.id),
            status: _vm.getModelStatus(model),
            statusColor: _vm.getModelStatusColor(model),
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.select($event, model.id)
            },
            dblclick: function ($event) {
              return _vm.handleFileDoubleClick(model.id)
            },
          },
          scopedSlots: _vm._u(
            [
              !model.previewUrl
                ? {
                    key: "imageDefaultSlot",
                    fn: function () {
                      return [_c("default-preview-svg")]
                    },
                    proxy: true,
                  }
                : null,
              {
                key: "badge",
                fn: function () {
                  return [
                    _c(_vm.modelUploadBadge(model.uploadOrigin), {
                      tag: "component",
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }