
import Vue from 'vue'
import { CsSimpleDialog, CsBtn } from '@consteel/csuetify'
import ExplorerProjectDataForm from './ExplorerProjectDataForm.vue'
import { fetchProjects, editOnStockProject } from '@/modules/onstock'

export default Vue.extend({
  components: { CsBtn, ExplorerProjectDataForm, CsSimpleDialog },
  name: 'ExplorerEditProject',
  data() {
    return {
      loading: false,
      editProjectDialog: false,
      projectData: {
        name: '',
        description: '',
        valid: true,
      },
    }
  },
  props: {
    maxWidth: { type: Number, default: 657 },
    projectId: {
      type: Number,
    },
    disabled: { type: Boolean, default: false },
    projectName: { type: String, default: '' },
    projectDescription: { type: String, default: '' },
  },
  mounted() {
    this.init()
  },
  computed: {
    disabledPrimaryButton(): boolean {
      return !this.projectData.valid
    },
  },
  watch: {
    editProjectDialog(value: true) {
      if (value) this.init()
    },
  },
  methods: {
    init() {
      this.projectData.name = this.projectName
      this.projectData.description = this.projectDescription
    },
    async submit() {
      const isValidProjectData = (
        (this.$refs.projectData as Vue)?.$refs.projectDataForm as HTMLFormElement
      )?.validate()

      if (!isValidProjectData) return

      this.loading = true
      await editOnStockProject(
        this.projectId.toString(),
        this.projectData.name,
        this.projectData.description
      )

      await fetchProjects()
      this.loading = false
      this.close()
    },
    close(): void {
      this.editProjectDialog = false
      this.$emit('close')
    },
  },
})
