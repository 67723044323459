
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
export default Vue.extend({
  name: 'ExplorerProjectDataForm',
  components: { CsFlex },
  props: {
    value: {
      type: Object as () => { name: string; description: string; valid: boolean },
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    nameRules() {
      return [
        (value) => {
          if (value) return true

          return 'A név megadása kötelező.'
        },
      ]
    },
    descriptionRules() {
      return [
        (value) => {
          if (value) return true

          return 'A leírás megadása kötelező.'
        },
      ]
    },
  },
})
