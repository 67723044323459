
import ExplorerToolbar from '@/components/Explorer/ExplorerToolbar.vue'
import {
  deleteExpiredPreviewsFromCache,
  getKeys,
  getModelFromCache,
  getModelPreviewUrlFromCache,
} from '@/services/cache.service'
import { CsFlex } from '@consteel/csuetify'
import { Unsubscribe } from '@firebase/util'
import Vue from 'vue'
import EmptySearchResult from '../components/Explorer/EmptySearchResult.vue'
import ExplorerGridView from '../components/Explorer/ExplorerGridView.vue'
import ExplorerHeader from '../components/Explorer/ExplorerHeader.vue'
import ExplorerInfoTab from '../components/Explorer/ExplorerInfoTab.vue'
import ExplorerListView from '../components/Explorer/ExplorerListView.vue'
import ExplorerNavigationDrawer from '../components/Explorer/ExplorerNavigationDrawer.vue'
import { FolderType, folderStore, setFolderLoading } from '../modules/folder'
import {
  SteelspaceModel,
  SteelspaceModelUserShare,
  getUserModelsSubscribe,
  modelStore,
  setModelLoading,
} from '../modules/model'
import {
  OnStockModel,
  OnStockModelStatus,
  OnStockProject,
  deleteOnStockModel,
  deleteOnStockProject,
  fetchProjects,
  getOnstockLoading,
  onStockStore,
} from '../modules/onstock'
import {
  getBandwidthLimitationSubscribe,
  getBandwidthUsageSubscribe,
  sendRequestMoreEmail,
} from '../modules/userService'
import { formatSize } from '../services/filesizeformatter.service'
import {
  ExplorerViewMode,
  explorerStore,
  setDeleteFileLoading,
  setDeleteFileShowDialog,
  setExplorerSearchKeyword,
  setImportedSteelspaceModelIds,
} from '../store/explorer.store'

export default Vue.extend({
  name: 'Explorer',
  components: {
    ExplorerListView,
    ExplorerInfoTab,
    ExplorerGridView,
    EmptySearchResult,
    CsFlex,
    ExplorerToolbar,
    ExplorerNavigationDrawer,
    ExplorerHeader,
  },
  data: () => ({
    tabs: null,
    creationTarget: '',
    currentProjectId: '',
    currentProjectType: 'own' as FolderType,
    selectedItems: [] as string[],
    infoBarToggle: false,
    showBandwidthLimitModal: false,
    showRequestMoreModal: false,
    fadedModelKeys: [] as string[],
    hasntRequested: true,
    firebaseUnsubs: [] as Unsubscribe[],
  }),
  computed: {
    onStockLoading(): boolean {
      return getOnstockLoading()
    },
    myProjects(): OnStockProject[] {
      return onStockStore.myProjects
    },
    collaboratedProjects(): OnStockProject[] {
      return onStockStore.collaboratedProjects
    },
    searchKeyword(): string {
      return explorerStore.searchKeyword
    },
    viewMode(): ExplorerViewMode {
      return explorerStore.viewMode
    },
    projects(): OnStockProject[] {
      if (this.currentProjectType === 'own') return this.myProjects

      if (this.currentProjectType === 'shared') return this.collaboratedProjects

      return []
    },
    currFolderIsShared(): boolean {
      return this.currentProjectType === 'shared'
    },
    models(): SteelspaceModel[] {
      return getUserModelsSubscribe()
    },
    bandwidthLimit(): number {
      return getBandwidthLimitationSubscribe()
    },
    bandwidthUsage(): number {
      return getBandwidthUsageSubscribe()
    },
    deleteDisabled(): boolean {
      return (
        !(this.currentProjectType === 'own') ||
        this.selectedItems.length !== 1 ||
        !(this.selectedProject || this.selectedModel) ||
        this.selectedProjectHasFinalizedModel ||
        this.selectedOnStockModel?.status === OnStockModelStatus.Finalized
      )
    },
    manageCollaboratorsDisabled(): boolean {
      if (this.currentProject) {
        return this.selectedItems.length !== 0
      }

      return this.selectedItems.length !== 1 || !this.selectedProject
    },
    importDisabled(): boolean {
      if (this.currentProject) {
        return this.selectedItems.length !== 0
      }

      return this.selectedItems.length !== 1 || !this.selectedProject
    },
    currentPath(): { text: string; id: string }[] {
      let currentPath = [] as { text: string; id: string }[]
      if (this.currentProjectType === 'own')
        currentPath.push({ id: this.currentProjectType, text: 'Projektjeim' })

      if (this.currentProjectType === 'shared')
        currentPath.push({ id: this.currentProjectType, text: 'Megosztva' })

      if (this.currentProjectId)
        currentPath.push({ id: this.currentProjectId, text: this.currentProject?.name || '' })

      return currentPath
    },
    currentProjectsOrSearchResult(): OnStockProject[] {
      if (this.currentProjectId) {
        return []
      } else {
        const currentProjectsOrSearchResult = this.searchKeyword
          ? this.projects.filter((project) =>
              project.name.toLowerCase().includes(this.searchKeyword.toLowerCase())
            )
          : this.projects
        return this.sortProjects(currentProjectsOrSearchResult)
      }
    },
    currentModelsOrSearchResult(): SteelspaceModel[] {
      const currentModelsOrSearchResult = this.searchKeyword
        ? this.currentModels.filter((model) =>
            model.name.toLowerCase().includes(this.searchKeyword.toLowerCase())
          )
        : this.currentModels
      return this.sortModels(currentModelsOrSearchResult)
    },
    currentProject(): OnStockProject | undefined {
      return this.projects.find((project) => project.id.toString() == this.currentProjectId)
    },
    currentModels(): SteelspaceModel[] {
      if (this.currentProject) {
        let models = [] as SteelspaceModel[]
        this.currentProject.models.forEach((onStockModel) => {
          const relevantModel = this.models.find(
            (spModel) =>
              spModel.id === onStockModel.steelspaceId ||
              spModel.originModelId === onStockModel.steelspaceId
          )

          if (relevantModel) models.push(relevantModel)
        })

        return models
      }
      return []
    },
    fileSystemLoading: {
      set(value: boolean): void {
        setModelLoading(value)
        setFolderLoading(value)
      },
      get(): boolean {
        return modelStore.loading || folderStore.loading
      },
    },
    selectedModelShares(): SteelspaceModelUserShare[] {
      if (this.selectedModel) {
        return this.selectedModel.getShares()
      } else {
        return new Array<SteelspaceModelUserShare>()
      }
    },
    selectedModel(): undefined | SteelspaceModel {
      if (this.selectedItems.length === 1) {
        const model = this.models.find((model) => model.id === this.selectedItems[0])
        if (model) return model
      }
      return undefined
    },
    selectedOnStockModel(): undefined | OnStockModel {
      if (this.selectedModel && this.currentProject)
        return (
          this.currentProject.models.find(
            (model) => model.steelspaceId == this.selectedModel?.id
          ) ||
          this.currentProject.models.find(
            (model) => model.steelspaceId == this.selectedModel?.originModelId
          )
        )
      return undefined
    },
    selectedProject(): undefined | OnStockProject {
      if (this.selectedItems.length === 1) {
        const project = this.projects.find((project) => {
          return project.id.toString() == this.selectedItems[0]
        })

        return project
      }
      return undefined
    },
    selectedProjectHasFinalizedModel(): boolean | undefined {
      return this.selectedProject?.models.some(
        (model) => model.status === OnStockModelStatus.Finalized
      )
    },
  },
  methods: {
    async computeFadedModelKeys(): Promise<void> {
      const keys = await getKeys()
      const models = [...this.models]

      const fadedModelKeys = models
        .filter((model) => {
          const previewUrl = getModelPreviewUrlFromCache(model)
          const modelIsOutOfBandwidth = model.size > this.bandwidthLimit - this.bandwidthUsage
          const modelIsNotInCacheWithPreviewUrl = !keys.includes(model.id) || !previewUrl

          return modelIsOutOfBandwidth && modelIsNotInCacheWithPreviewUrl
        })
        .map((model) => model.id)

      this.fadedModelKeys = fadedModelKeys
    },
    navigate({ folderId, skipHistory = false }: { folderId: string; skipHistory: boolean }): void {
      let _folderId = folderId
      if (['own', 'shared'].includes(folderId)) {
        this.setCurrentProjectType(folderId as FolderType)
        _folderId = ''
      }

      this.currentProjectId = _folderId
      this.unselectAll()
      this.infoBarToggle = false
      setExplorerSearchKeyword('')

      if (!skipHistory) {
        history.pushState(null, 'Steelspace Explorer', `/explorer/${_folderId}`)
      }
    },
    select({ event, id }: { event: MouseEvent; id: string }): void {
      if (!event.ctrlKey) {
        this.selectedItems = []
      }

      if (this.selectedItems.includes(id)) {
        this.selectedItems.splice(this.selectedItems.indexOf(id), 1)
      } else {
        this.selectedItems.push(id)
      }
    },
    unselectAll(event?: MouseEvent): void {
      if (!event || !event.ctrlKey) {
        this.selectedItems = []
      }
    },
    setCurrentProjectType(newType: FolderType): void {
      this.currentProjectType = newType
      this.currentProjectId = ''
    },
    async deleteFile(): Promise<void> {
      setDeleteFileLoading(true)
      if (this.selectedProject) {
        await deleteOnStockProject(this.selectedProject.id.toString())
      } else if (this.selectedOnStockModel && this.currentProjectId) {
        await deleteOnStockModel(this.currentProjectId, this.selectedOnStockModel.id.toString())
      }
      await fetchProjects()
      this.computeImportedSteelspaceModelIds()
      setDeleteFileLoading(false)
      setDeleteFileShowDialog(false)
      this.selectedItems = []
    },
    computeImportedSteelspaceModelIds(): void {
      const modelIds = this.projects.reduce((modelIds, currentProject): string[] => {
        const projectModels = currentProject.models.map((model) => model.steelspaceId)
        return [...modelIds, ...projectModels]
      }, [] as string[])
      setImportedSteelspaceModelIds(modelIds)
    },
    handleLinkNavigation(): void {
      if (this.myProjects && this.collaboratedProjects && !this.onStockLoading) {
        // Ha van paraméter és az lézetik a mappák között akkor oda navigálunk
        if (this.$route.params.projectId) {
          const myProject = this.myProjects.find(
            (item) => item.id.toString() == this.$route.params.projectId
          )
          const collaboratedProject = this.collaboratedProjects.find(
            (item) => item.id.toString() == this.$route.params.projectId
          )

          if (myProject) this.currentProjectType = 'own'
          if (collaboratedProject) this.currentProjectType = 'shared'

          this.navigate({ folderId: this.$route.params.projectId, skipHistory: true })
          // Ez itt nem halhat meg elvileg soha, ha már befejeztük a töltést
        } else {
          // Visszafele lépkedve a history-ban az üres explorer-hez érkezünk vagy nem létező mappa
          this.setCurrentProjectType('own')
          if (this.$route.params.folderId) {
            // Van folderId, de nem létező helyre mutat
            history.replaceState(null, 'Steelspace Explorer', '/explorer')
          }
        }
      } else {
        setTimeout(this.handleLinkNavigation, 200)
      }
    },
    async handleFileDoubleClick(modelId: string): Promise<void> {
      const modelToOpen = this.models.find((model) => model.id === modelId)

      if (!modelToOpen) {
        throw new Error('This should never happen: no model to open')
      }

      const cachedModel = await getModelFromCache(modelToOpen.id, modelToOpen.lastModificationDate)

      if (cachedModel || this.bandwidthLimit - this.bandwidthUsage > modelToOpen.size) {
        this.$router.push(`/model/${modelId}`)
      } else {
        this.showBandwidthLimitModal = true
      }
    },
    async requestMore() {
      await sendRequestMoreEmail()
    },
    sortProjects(projects: OnStockProject[]): OnStockProject[] {
      if (explorerStore.orderBy === 'alphabetical') {
        return [...projects].sort((a, b) => a.name.localeCompare(b.name))
      }
      return [...projects].sort((a, b) => +new Date(b.creationDate) - +new Date(a.creationDate))
    },
    sortModels(models: SteelspaceModel[]): SteelspaceModel[] {
      if (explorerStore.orderBy === 'alphabetical') {
        return [...models].sort((a, b) => a.name.localeCompare(b.name))
      }
      return [...models].sort((a, b) => +new Date(b.creationDate) - +new Date(a.creationDate))
    },
    formatSize: formatSize,
  },
  watch: {
    async models(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    async publicModels(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    async bandwidthUsage(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    searchKeyword(): void {
      this.selectedItems = []
    },
  },

  async created(): Promise<void> {
    await fetchProjects()
    this.computeImportedSteelspaceModelIds()
    this.handleLinkNavigation()

    deleteExpiredPreviewsFromCache()
  },
  async mounted() {
    await this.computeFadedModelKeys()
  },
})
