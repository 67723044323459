var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "explorer",
      attrs: { vertical: "", "fill-height": "", justify: "flex-start" },
    },
    [
      _c("explorer-header"),
      _c("v-divider"),
      _c(
        "cs-flex",
        { attrs: { "fill-width": "", "fill-height": "", align: "stretch" } },
        [
          _c("explorer-navigation-drawer", {
            attrs: {
              projects: _vm.projects,
              fileSystemLoading: _vm.fileSystemLoading,
              currentProjectId: _vm.currentProjectId,
              currentProjectType: _vm.currentProjectType,
            },
            on: { navigate: _vm.navigate },
          }),
          _c(
            "v-main",
            [
              _c(
                "cs-flex",
                {
                  staticClass: "main-container",
                  attrs: {
                    vertical: "",
                    "fill-height": "",
                    justify: "flex-start",
                  },
                },
                [
                  _c("explorer-toolbar", {
                    attrs: {
                      project: _vm.selectedProject || _vm.currentProject,
                      model: _vm.selectedModel,
                      importDisabled: _vm.importDisabled,
                      manageCollaboratorsDisabled:
                        _vm.manageCollaboratorsDisabled,
                      fileSystemLoading:
                        _vm.fileSystemLoading || _vm.onStockLoading,
                      currentPath: _vm.currentPath,
                      deleteDisabled: _vm.deleteDisabled,
                    },
                    on: { deleteFile: _vm.deleteFile, navigate: _vm.navigate },
                    model: {
                      value: _vm.infoBarToggle,
                      callback: function ($$v) {
                        _vm.infoBarToggle = $$v
                      },
                      expression: "infoBarToggle",
                    },
                  }),
                  _c("v-divider"),
                  _c(
                    "cs-flex",
                    {
                      class: [
                        {
                          "fs-loading":
                            _vm.fileSystemLoading || _vm.onStockLoading,
                        },
                      ],
                      attrs: {
                        "fill-width": "",
                        "fill-height": "",
                        align: "stretch",
                        gap: "2rem",
                      },
                    },
                    [
                      _vm.currentModelsOrSearchResult?.length ||
                      _vm.currentProjectsOrSearchResult?.length
                        ? _c(
                            "cs-flex",
                            {
                              staticClass: "explorer-container py-4",
                              attrs: {
                                "fill-height": "",
                                "fill-width": "",
                                align: "flex-start",
                              },
                              on: { click: _vm.unselectAll },
                            },
                            [
                              _vm.viewMode === "grid"
                                ? _c("explorer-grid-view", {
                                    attrs: {
                                      selectedItems: _vm.selectedItems,
                                      fadedModelKeys: _vm.fadedModelKeys,
                                      models: _vm.currentModelsOrSearchResult,
                                      projects:
                                        _vm.currentProjectsOrSearchResult,
                                      onStockModels: _vm.currentProject?.models,
                                    },
                                    on: {
                                      navigate: _vm.navigate,
                                      select: _vm.select,
                                      handleFileDoubleClick:
                                        _vm.handleFileDoubleClick,
                                    },
                                  })
                                : _vm._e(),
                              _vm.viewMode === "list"
                                ? _c("explorer-list-view", {
                                    attrs: {
                                      selectedItems: _vm.selectedItems,
                                      fadedModelKeys: _vm.fadedModelKeys,
                                      models: _vm.currentModelsOrSearchResult,
                                      projects:
                                        _vm.currentProjectsOrSearchResult,
                                      onStockModels: _vm.currentProject?.models,
                                    },
                                    on: {
                                      navigate: _vm.navigate,
                                      select: _vm.select,
                                      handleFileDoubleClick:
                                        _vm.handleFileDoubleClick,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("empty-search-result", {
                            attrs: { searchKeyword: _vm.searchKeyword },
                          }),
                      _c("explorer-info-tab", {
                        attrs: {
                          bandwidthLimit: _vm.bandwidthLimit,
                          bandwidthUsage: _vm.bandwidthUsage,
                          currFolderIsShared: _vm.currFolderIsShared,
                          selectedModel: _vm.selectedModel,
                          selectedOnStockModel: _vm.selectedOnStockModel,
                          selectedProject:
                            _vm.selectedProject || _vm.currentProject,
                        },
                        on: {
                          showBandwidthLimitModal: function ($event) {
                            _vm.showBandwidthLimitModal = true
                          },
                        },
                        model: {
                          value: _vm.infoBarToggle,
                          callback: function ($$v) {
                            _vm.infoBarToggle = $$v
                          },
                          expression: "infoBarToggle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }