var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("import-model-dialog", {
        attrs: { loading: _vm.loading },
        on: { submit: _vm.handleImportModels },
        model: {
          value: _vm.importModelsDialog,
          callback: function ($$v) {
            _vm.importModelsDialog = $$v
          },
          expression: "importModelsDialog",
        },
      }),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              _vm.importModelsDialog = true
                            },
                          },
                        },
                        "v-btn",
                        { ...attrsTooltip },
                        false
                      ),
                      { ...onTooltip }
                    ),
                    [_c("v-icon", [_vm._v("mdi-upload")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("Import")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }