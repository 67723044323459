
import { CsBtn, CsFlex, CsSimpleDialog } from '@consteel/csuetify'
import Vue from 'vue'
import { explorerStore, setDeleteFileShowDialog } from '../../store/explorer.store'

export default Vue.extend({
  name: 'ExplorerDeleteFile',
  components: {
    CsBtn,
    CsFlex,
    CsSimpleDialog,
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit(): void {
      this.$emit('submit')
    },
  },
  computed: {
    showDeleteConfirmationModal: {
      get: function (): boolean {
        return explorerStore.deleteFile.showDialog
      },
      set(value: boolean) {
        setDeleteFileShowDialog(value)
      },
    },
    loading(): boolean {
      return explorerStore.deleteFile.loading
    },
  },
})
