var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        title: _vm.$t("Project szerkeztése"),
        persistent: "",
        maxWidth: _vm.maxWidth,
        "fill-width": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onDialog, attrs: attrsDialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      fab: "",
                                      text: "",
                                      small: "",
                                      disabled: _vm.disabled,
                                    },
                                  },
                                  "v-btn",
                                  { ...attrsDialog, ...attrsTooltip },
                                  false
                                ),
                                { ...onDialog, ...onTooltip }
                              ),
                              [
                                _c("v-icon", { attrs: { size: 20 } }, [
                                  _vm._v("mdi-pencil-outline"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Projekt adatok módosítása"))),
                  ]),
                ]
              ),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "me-3",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.$t("Mégse")))]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        disabled: !_vm.disabledPrimaryButton,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({ staticClass: "d-inline" }, on),
                                [
                                  _c(
                                    "cs-btn",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled: _vm.disabledPrimaryButton,
                                      },
                                      on: { click: _vm.submit },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("Módosítás")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Kérjük töltsd ki helyesen a kötelező mezőket"
                            )
                          ) + "!"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.editProjectDialog,
        callback: function ($$v) {
          _vm.editProjectDialog = $$v
        },
        expression: "editProjectDialog",
      },
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", absolute: "", top: "" },
          })
        : _vm._e(),
      _c("explorer-project-data-form", {
        ref: "projectData",
        model: {
          value: _vm.projectData,
          callback: function ($$v) {
            _vm.projectData = $$v
          },
          expression: "projectData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }