var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-navigation-drawer",
    { staticClass: "py-4 px-6 nav-drawer" },
    [
      _vm.fileSystemLoading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: { block: "", large: "" },
          on: { click: _vm.handleClickCreateProjectButton },
        },
        [_vm._v(_vm._s(_vm.$t("+ Project létrehozása")))]
      ),
      _c("create-project-dialog", {
        on: {
          close: function ($event) {
            return _vm.navigate("own")
          },
        },
        model: {
          value: _vm.createProjectDialog,
          callback: function ($$v) {
            _vm.createProjectDialog = $$v
          },
          expression: "createProjectDialog",
        },
      }),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: {
            color: "gray",
            block: "",
            large: "",
            outlined: _vm.currentProjectType === "own",
            disabled: _vm.fileSystemLoading,
          },
          on: {
            click: function ($event) {
              return _vm.navigate("own")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Projektjeim")))]
      ),
      _vm.currentProjectType == "own"
        ? _c("cs-filetree", {
            staticClass: "mt-4 mb-8 folder-tree pa-1",
            attrs: {
              items: _vm.projectTree,
              value: _vm.currentProjectId,
              loading: _vm.fileSystemLoading,
            },
            on: { input: _vm.navigate },
          })
        : _vm._e(),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: {
            color: "gray",
            block: "",
            large: "",
            outlined: _vm.currentProjectType === "shared",
            disabled: _vm.fileSystemLoading,
          },
          on: {
            click: function ($event) {
              return _vm.navigate("shared")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Megosztva")))]
      ),
      _vm.currentProjectType == "shared"
        ? _c("cs-filetree", {
            staticClass: "mt-4 mb-8",
            attrs: {
              items: _vm.projectTree,
              value: _vm.currentProjectId,
              loading: _vm.fileSystemLoading,
            },
            on: { input: _vm.navigate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }