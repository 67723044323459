var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              _vm.manageCollaboratorsDialog =
                                !_vm.manageCollaboratorsDialog
                            },
                          },
                        },
                        "v-btn",
                        { ...attrsTooltip },
                        false
                      ),
                      { ...onTooltip }
                    ),
                    [
                      _c("v-icon", { attrs: { size: _vm.iconSize } }, [
                        _vm._v(_vm._s(_vm.icon)),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("Megosztási beállítások")))])]
      ),
      _c("manage-collaborators-dialog", {
        attrs: {
          projectId: _vm.projectId,
          currentProject: _vm.currentProject,
          collaborators: _vm.collaborators,
        },
        model: {
          value: _vm.manageCollaboratorsDialog,
          callback: function ($$v) {
            _vm.manageCollaboratorsDialog = $$v
          },
          expression: "manageCollaboratorsDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }