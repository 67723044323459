var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "cs-project-info pa-4",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "0.5rem",
      },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "cs-project-info-scrollable-content",
          attrs: { vertical: "", gap: "0.5rem", justify: "flex-start" },
        },
        [
          _c(
            "div",
            [
              _c(
                "v-img",
                [
                  _c(
                    "cs-flex",
                    {
                      attrs: {
                        align: "center",
                        justify: "center",
                        fillWidth: "",
                        fillHeight: "",
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "folder-icon" }, [
                        _vm._v(" mdi-folder "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("explorer-manage-collaborators", {
                attrs: {
                  collaborators: _vm.project?.collaborators,
                  projectId: _vm.project?.id,
                  iconSize: 20,
                  disabled: _vm.disabledManageCollaborators,
                  icon: "mdi-share-variant-outline",
                  currentProject: _vm.project,
                },
              }),
              _vm.project.collaborators.length
                ? _c(
                    "div",
                    { staticClass: "collaborator-container d-flex" },
                    [
                      _vm._l(
                        _vm.project.collaborators,
                        function (collaborator, index) {
                          return [
                            _c(
                              "a",
                              {
                                key: index,
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("openShare")
                                  },
                                },
                              },
                              [
                                _c("cs-tooltip-avatar", {
                                  staticClass: "ml-1",
                                  attrs: {
                                    email: collaborator.email,
                                    tooltipText: `${
                                      collaborator.name || collaborator.email
                                    }`,
                                    size: 24,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-center mb-3 justify-space-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "font-weight-black project-info-title" },
                    [_vm._v(_vm._s(_vm.$t("Project adatok")))]
                  ),
                  _c("explorer-edit-project", {
                    attrs: {
                      projectId: _vm.project.id,
                      projectName: _vm.project.name,
                      disabled: _vm.disabledEdit,
                      projectDescription: _vm.project.description,
                    },
                  }),
                ],
                1
              ),
              _c(
                "cs-flex",
                {
                  staticClass: "align-start project-name mb-1",
                  attrs: { justify: "space-between" },
                },
                [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm.$t("Projekt név"))),
                  ]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.project.name)),
                  ]),
                ]
              ),
              _c(
                "cs-flex",
                {
                  staticClass: "align-start project-description mb-1",
                  attrs: { justify: "space-between" },
                },
                [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm.$t("Leírás"))),
                  ]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.project.description)),
                  ]),
                ]
              ),
              _c(
                "cs-flex",
                {
                  staticClass: "align-start project-status mb-1",
                  attrs: { justify: "space-between" },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Státusz")))]),
                  _c("span", [_vm._v(_vm._s(_vm.getProjectStatus))]),
                ]
              ),
              _vm.project.creationDate
                ? _c(
                    "cs-flex",
                    {
                      staticClass: "align-start project-status mb-1",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("Létrehozás dátuma")))]),
                      _c("span", [_vm._v(_vm._s(_vm.creationDate))]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "div",
                {
                  staticClass: "mt-3 mb-2 font-weight-black project-info-title",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("Együttműködők és jogosultságok")) + " "
                  ),
                ]
              ),
              _vm._l(_vm.project.collaborators, function (collaborator) {
                return _c(
                  "cs-flex",
                  {
                    key: collaborator.id,
                    staticClass: "mb-1",
                    attrs: { justify: "space-between" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(collaborator.name || collaborator.email)),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(collaborator.roles.join(", ")) + " "),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }