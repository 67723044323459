
import Vue from 'vue'
import { CsBtn, CsNavigationDrawer, CsFiletree, TreeNode } from '@consteel/csuetify'
import CreateProjectDialog from '../CreateProject/CreateProjectDialog.vue'
import { FolderType } from '../../modules/folder'
import { OnStockProject } from '../../modules/onstock'

export default Vue.extend({
  name: 'ExplorerNavigationDrawer',
  components: {
    CsFiletree,
    CsBtn,
    CsNavigationDrawer,
    CreateProjectDialog,
  },
  data() {
    return {
      createProjectDialog: false,
    }
  },
  props: {
    projects: {
      type: Array as () => OnStockProject[],
    },
    fileSystemLoading: {
      type: Boolean,
      default: false,
    },
    currentProjectId: {
      type: String,
    },
    currentProjectType: {
      type: String as () => FolderType,
    },
  },
  computed: {
    projectTree(): TreeNode[] {
      return this.projects.map((project) => ({
        id: project.id.toString(),
        name: project.name,
        children: [],
      }))
    },
  },
  methods: {
    handleClickCreateProjectButton() {
      this.createProjectDialog = true
    },
    navigate(folderId: number, skipHistory = false) {
      this.$emit('navigate', { folderId: folderId.toString(), skipHistory })
    },
  },
})
