var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        fill: _vm.color,
        viewBox: "0 0 580 580",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "m198.07,355.93c11.76,0,22.81-4.64,31.13-13.07,0,0,53.47-54.2,53.48-54.2l-36.72-37.22-84.55,85.81,5.54,5.61c8.32,8.43,19.37,13.07,31.13,13.07Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m214.03,75.74c-11.76,0-22.82,4.64-31.13,13.07l-102.17,103.56c-36.73,41.53,11.46,73.34,37.56,101.18l36.72-37.22-31.97-32.41,90.99-92.24c4.97,5.04,71.31,72.29,75.03,76.05h0s36.72,37.22,36.72,37.22c28.77-25.51,21.96-57.46-5.75-80.27,0,0-74.86-75.89-74.86-75.89-8.32-8.43-19.37-13.07-31.13-13.07Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m456.55,285.22l-39.91,40.45,30.15,30.57-87.8,89-24.35-24.69-88.06-87.91-6.52,6.61c-8.74,8.86-13.56,20.64-13.56,33.17,0,12.53,4.81,24.31,13.56,33.17l54.67,55.41,31.54,31.97c8.74,8.86,20.36,13.74,32.73,13.74s23.98-4.88,32.72-13.74h0s102.17-103.57,102.17-103.57c18.04-18.29,18.04-48.05,0-66.34l-37.34-37.85Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m374.96,221.94c-12.36,0-23.98,4.88-32.72,13.74,0,0-52.72,53.44-52.72,53.44l38.66,38.84,85.27-86.44-5.76-5.84c-8.74-8.86-20.36-13.74-32.73-13.74Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m234,239.32c-28.93-21.92-53.91-5.3-74.19,18.63,0,0-1.6,1.62-1.6,1.62l-36.72,37.22c-14.64,17.27-47.35,38.47-45.46,64.56,0,11.92,4.58,23.13,12.89,31.55l102.17,103.56c40.58,36.22,69.96-8.82,97.25-35.47l-36.72-37.22-29.4,29.8-90.99-92.24c32.78-33.22,78.61-79.68,111.53-113.15l-8.76-8.88Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m333.59,340.28l-4.17-4.23h0l-43.1-43.69-36.55,37.05,43.7,44.29h0s40.87,40.68,40.87,40.68c24.27-20.48,24.15-54.24-.75-74.11Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m234.62,170.37c-18.19,18.1-16.75,48.67,2.36,65.5,8.83,8.08,40,40.45,49.06,49.38l36.55-37.05c-6.73-6.82-39.67-40.21-45.66-46.29h0s-36.72-37.22-36.72-37.22l-5.59,5.67Z",
        },
      }),
      _c("path", {
        attrs: {
          d: "m383.08,82.98c-16.39-17.31-45.87-17.31-62.26,0,0,0-37.51,38.02-37.51,38.02l36.72,37.22,31.92-32.36,90.99,92.24c-32.85,33.29-78.58,79.65-111.58,113.1,16.3,21.55,48.23,25.98,67.68,5.84.89-.94,14.13-14.29,14.41-14.6,18.33-18.62,53.69-54.39,71.8-72.78,17.08-16.61,17.08-46.5,0-63.11,0,0-102.17-103.56-102.17-103.56Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }