var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "cs-model-info pa-4",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
        gap: "0.5rem",
      },
    },
    [
      !_vm.empty
        ? _c(
            "cs-flex",
            {
              staticClass: "cs-model-info-scrollable-content pr-1",
              attrs: { vertical: "", gap: "1rem", justify: "flex-start" },
            },
            [
              _c(
                "cs-flex",
                { attrs: { "fill-width": "" } },
                [
                  _c(
                    "v-img",
                    {
                      attrs: {
                        src: _vm.thumbnail,
                        width: "100%",
                        "aspect-ratio": 16 / 9,
                      },
                    },
                    [
                      [
                        _vm.thumbnail
                          ? _vm._t("imageDefaultSlot")
                          : _c(
                              "cs-flex",
                              {
                                attrs: { "fill-width": "", "fill-height": "" },
                              },
                              [_vm._t("imageDefaultSlot")],
                              2
                            ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "cs-flex",
                { attrs: { justify: "flex-start", gap: "0.5rem" } },
                [
                  _c(
                    "v-icon",
                    {
                      class: _vm.shareDisabled ? "share-click-disabled" : "",
                      attrs: { size: "20" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("openShare")
                        },
                      },
                    },
                    [_vm._v("mdi-share-variant-outline")]
                  ),
                  _c("span", { staticClass: "share-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.shares.length
                          ? _vm.$t("Shared")
                          : _vm.$t("Not shared")
                      )
                    ),
                  ]),
                  _vm.shares.length
                    ? _c(
                        "div",
                        {
                          staticClass: "share-container d-flex",
                          attrs: { gap: "0.2rem" },
                        },
                        [
                          _vm._l(_vm.shares, function (share, index) {
                            return [
                              _c(
                                "a",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("openShare")
                                    },
                                  },
                                },
                                [
                                  _c("cs-tooltip-avatar", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      email: share.user.email,
                                      tooltipText: `${
                                        share.user.name?.trim() ||
                                        share.user.email
                                      }`,
                                      size: 24,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "table",
                [
                  _c(
                    "div",
                    { staticClass: "model-info-title font-weight-black mb-3" },
                    [_vm._v("Modell adatok")]
                  ),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("Name")))]),
                    _c("td", [_vm._v(_vm._s(_vm.title))]),
                  ]),
                  _vm._l(_vm.infos, function (info) {
                    return _c("tr", { key: info.key, staticClass: "mb-1" }, [
                      _c("td", [_vm._v(_vm._s(info.key))]),
                      _c("td", [_vm._v(_vm._s(info.value))]),
                    ])
                  }),
                ],
                2
              ),
              _vm.description
                ? _c("cs-flex", { attrs: { vertical: "" } }, [
                    _c("span", [
                      _c("strong", [_vm._v(_vm._s(_vm.descriptionCaption))]),
                    ]),
                    _c("br"),
                    _c("pre", { staticClass: "description" }, [
                      _vm._v(_vm._s(_vm.description)),
                    ]),
                  ])
                : _vm._e(),
              _vm.tags.length
                ? _c("cs-flex", { attrs: { vertical: "" } }, [
                    _c("span", { staticClass: "tags" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("Tags")))]),
                    ]),
                    _c(
                      "span",
                      { staticClass: "tags-content" },
                      [
                        _vm._l(_vm.tags, function (tag) {
                          return [_vm._v(" " + _vm._s(`#${tag} `) + " ")]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c(
                "cs-flex",
                { attrs: { vertical: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "font-weight-black mb-3 model-info-title" },
                    [_vm._v("Project adatok")]
                  ),
                  _c(
                    "cs-flex",
                    {
                      staticClass: "align-start project-name mb-1",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c("div", { staticClass: "flex-1" }, [
                        _vm._v(_vm._s(_vm.$t("Projekt név"))),
                      ]),
                      _c("div", { staticClass: "flex-1 text-right" }, [
                        _vm._v(_vm._s(_vm.onStockModel.project.name)),
                      ]),
                    ]
                  ),
                  _c(
                    "cs-flex",
                    {
                      staticClass: "align-start project-description mb-1",
                      attrs: { justify: "space-between" },
                    },
                    [
                      _c("div", { staticClass: "flex-1" }, [
                        _vm._v(_vm._s(_vm.$t("Leírás"))),
                      ]),
                      _c("div", { staticClass: "flex-1 text-right" }, [
                        _vm._v(_vm._s(_vm.onStockModel.project.description)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "cs-flex",
                { attrs: { vertical: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-3 mb-2 font-weight-black model-info-title",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Együttműködők és jogosultságok")) +
                          " "
                      ),
                    ]
                  ),
                  _vm._l(_vm.shares, function (share) {
                    return _c(
                      "cs-flex",
                      {
                        key: share.id,
                        staticClass: "mb-1",
                        attrs: { justify: "space-between" },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(share.user.name || share.user.email)),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.getCollaboratorRoles(share.user))),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "cs-flex",
            {
              staticClass: "cs-model-info-scrollable-content",
              attrs: { vertical: "", gap: "1rem", justify: "flex-start" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-img",
                    [
                      _c(
                        "cs-flex",
                        {
                          attrs: {
                            align: "center",
                            justify: "center",
                            fillWidth: "",
                            fillHeight: "",
                          },
                        },
                        [_c("v-icon", [_vm._v(" mdi-file ")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("cs-flex", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("See file or folder details here."))),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }